<template>
  <div class="py-6 lg:py-24 px-5 text-center" :style="{ backgroundColor: backgroundColour || '' }">
    <h3 class="heading-2">{{ blok.title }}</h3>
    <p class="max-w-2xl mx-auto mt-4" :style="{ color: subtitleColour || '' }">
      {{ blok.subtitle }}
    </p>
    <BookingPostcode class="w-full max-w-md mx-auto mt-2 mt-10" />
    <p class="text-grey-dark">{{ blok.belowButtonText }}</p>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
});

const subtitleColour = computed(() => {
  console.log(props.blok.subtitleColour)
  return props.blok.subtitleColour?.value || '';
});

const backgroundColour = computed(() => {
  return props.blok.backgroundColour?.value || '';
});
</script>
